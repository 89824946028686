














































import { Component, Vue, Watch } from 'vue-property-decorator';
import PublicPage from '@/components/swine/layout/PublicPage.vue';
import AuthenticationForm from '@/components/general/layout/AuthenticationForm.vue';
import ForgetPasswordStore from '@/store/swine/login/forget-password.store';
import { validatePassword } from '@/utils/validation';
import { ToastProgrammatic } from 'buefy';
import {
  ResetPasswordParams,
  ValidateTokenParams,
} from '@/models/swine/login/login.model';

@Component({
  components: {
    PublicPage,
    AuthenticationForm,
  },
})
export default class ResetPassword extends Vue {
  title = 'Reset Password';
  confirmPassword = '';
  password = '';
  errorMessage = 'New Password and Confirm New Password are different';
  forgetPasswordStore = ForgetPasswordStore;
  isButtonDisabled = false;
  isPasswordAccepted = true;

  created() {
    this.disableButton();
  }

  mounted() {
    const params: ValidateTokenParams = {
      token: this.$route.query.token as string,
    };
    this.forgetPasswordStore.validateToken(params);
  }

  @Watch('forgetPasswordStore.tokenValidationResponse')
  validateToken() {
    if (
      this.forgetPasswordStore.tokenValidationResponse &&
      this.forgetPasswordStore.tokenValidationResponse.message &&
      this.forgetPasswordStore.tokenValidationResponse.message.includes(
        'failed with status code 500',
      )
    ) {
      ToastProgrammatic.open({
        type: 'is-danger',
        message: 'Token has expired. Redirecting to Login',
        duration: 3000,
      });
      this.$router.push({
        name: 'Login',
      });
    }
  }

  @Watch('confirmPassword')
  @Watch('password')
  disableButton() {
    this.isButtonDisabled = true;
    this.isPasswordAccepted = false;
    this.errorMessage = 'New Password and Confirm New Password are different';

    if (this.confirmPassword.length === 0 && this.password.length === 0) {
      this.isPasswordAccepted = true;
    }

    if (
      this.confirmPassword === this.password &&
      validatePassword(this.confirmPassword)
    ) {
      this.isButtonDisabled = false;
      this.isPasswordAccepted = true;
    }
  }

  submitResetPasswordForm() {
    const params: ResetPasswordParams = {
      confirmPassword: this.confirmPassword,
      password: this.password,
      token: this.$route.query.token as string,
    };
    this.forgetPasswordStore.submitResetPasswordForm(params);
  }

  @Watch('forgetPasswordStore.resetPasswordResponse')
  checkCurrentStatus() {
    if (
      this.forgetPasswordStore.resetPasswordResponse &&
      this.forgetPasswordStore.resetPasswordResponse.status === 200
    ) {
      this.$router.push({
        name: 'Login',
      });
    } else if (
      this.forgetPasswordStore.resetPasswordResponse &&
      this.forgetPasswordStore.resetPasswordResponse.message.includes(
        'failed with status code 500',
      )
    ) {
      this.isPasswordAccepted = false;
      this.errorMessage =
        'Please set a new password that is unique from your old password';
    }
  }
}

import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import {
  ResetPasswordParams,
  ValidateTokenParams,
} from '@/models/swine/login/login.model';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { ToastProgrammatic } from 'buefy';

//TODO: Set up everything once API is done
@Module({
  namespaced: true,
  dynamic: true,
  name: 'forget-password.store',
  store,
})
class ForgetPasswordStore extends VuexModule {
  forgetPasswordResponse: any | null = null;
  resetPasswordResponse: any | null = null;
  tokenValidationResponse: any | null = null;
  isLoading = false;
  error: any | null = null;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setForgetPasswordResponse(payload: any) {
    this.forgetPasswordResponse = payload;
  }

  @Mutation
  setResetPasswordResponse(payload: any) {
    this.resetPasswordResponse = payload;
  }

  @Mutation
  setTokenValidationResponse(payload: any) {
    this.tokenValidationResponse = payload;
  }

  @Action
  submitForgetPasswordDetails(email: string) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit('setForgetPasswordResponse', null);
    try {
      axios
        .post(Endpoints.ForgetPassword, {
          email: email,
        })
        .then((response) => {
          if (response.status === 201) {
            ToastProgrammatic.open({
              type: 'is-dark',
              message: 'Reset link sent',
              duration: 3000, // 3 seconds
            });
          }
          this.context.commit('setForgetPasswordResponse', response);
        })
        .catch((error) => {
          this.context.commit('setError', error);
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async submitResetPasswordForm(params: ResetPasswordParams) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit('setResetPasswordResponse', null);
    try {
      axios
        .put(Endpoints.ResetPassword, params)
        .then((response) => {
          if (response.status === 200) {
            ToastProgrammatic.open({
              type: 'is-dark',
              message: 'Password is updated',
              duration: 3000, // 3 seconds
            });
          }
          this.context.commit('setResetPasswordResponse', response);
        })
        .catch((error) => {
          this.context.commit('setError', error);
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async validateToken(params: ValidateTokenParams) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    this.context.commit('setTokenValidationResponse', null);
    try {
      axios
        .get(Endpoints.TokenValidation, {
          params,
        })
        .then((response) => {
          this.context.commit('setTokenValidationResponse', response);
        })
        .catch((error) => {
          this.context.commit('setError', error);
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(ForgetPasswordStore);
